import React from "react"
import { FaRegSurprise } from "react-icons/fa"
import styled from "styled-components"
import { Link } from "gatsby"

const ErrorStyles = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .error {
    text-align: center;
    font-size: 25px;
  }

  .error__icon {
    font-size: 130px;
    color: var(--clr-primary);
  }

  h2 {
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: 700;
  }

  h4 {
    margin-bottom: 30px;
    font-weight: 700;
  }

  p {
    margin-bottom: 70px;
  }

  a {
    font-weight: 700;
    font-family: "Open sans";
    text-decoration: none;
    text-transform: uppercase;
    font-size: 22px;
    color: var(--clr-black);
    padding: 15px 40px;
    border: var(--border);
    border-radius: 5px;
  }

  @media (max-width: 1000px) {
    .error {
      font-size: 20px;
    }
    h2 {
      font-size: 28px;
      margin-bottom: 26px;
      font-weight: 700;
    }
    h4 {
      margin-bottom: 30px;
      font-weight: 700;
    }
    a {
      font-size: 16px;
      padding: 10px 30px;
    }
    p {
      margin-bottom: 60px;
    }
    .error__icon {
      font-size: 110px;
    }
  }
  @media (max-width: 440px) {
    .error {
      width: 90%;
    }
  }
`

function FourOhFourPage() {
  return (
    <>
      <ErrorStyles>
        <div className="error">
          <div className="error__icon">
            <FaRegSurprise />
          </div>
          <h2 className="mb">404 - Page not found</h2>
          <p className="error__text">
            The page you are looking for doesn't exist
          </p>
          <Link to="/home">go back to home</Link>
        </div>
      </ErrorStyles>
    </>
  )
}

export default FourOhFourPage
